export const suffering = {
  text: 'Suffering',
  explanation:
    'This is the noble truth of suffering: Rebirth is suffering; old age is suffering; illness is suffering; death is suffering; association with the disliked is suffering; separation from the liked is suffering; not getting what you wish for is suffering. In brief, the five grasping aggregates are suffering.',
  seeMore: ['SN 56.11', 'SN 56.13', 'SN 56.14', 'SN 35.28', 'AN 6.63'],
  children: [
    {
      id: 'S-1',
      text: 'Rebirth',
      elaborations: [
        {
          reference: 'MN 141',
          text: [
            'What is rebirth? The rebirth, inception, conception, reincarnation, manifestation of the aggregates, and acquisition of the sense fields of the various sentient beings in the various orders of sentient beings. This is called rebirth.',
          ],
          spokenBy: 'Ven. Sariputta',
        },
      ],
    },
    {
      id: 'S-2',
      text: 'Old age',
      elaborations: [
        {
          reference: 'MN 141',
          text: [
            'What is old age? The old age, decrepitude, broken teeth, grey hair, wrinkly skin, diminished vitality, and failing faculties of the various sentient beings in the various orders of sentient beings. This is called old age.',
          ],
          spokenBy: 'Ven. Sariputta',
        },
      ],
    },
    {
      id: 'S-3',
      text: 'Death',
      elaborations: [
        {
          reference: 'MN 141',
          text: [
            'What is death? The passing away, perishing, disintegration, demise, mortality, death, decease, breaking up of the aggregates, laying to rest of the corpse, and cutting off of the life faculty of the various sentient beings in the various orders of sentient beings. This is called death.',
          ],
          spokenBy: 'Ven. Sariputta',
        },
      ],
    },
    {
      id: 'S-4',
      text: 'Sorrow',
      elaborations: [
        {
          reference: 'MN 141',
          text: [
            'What is sorrow? The sorrow, sorrowing, state of sorrow, inner sorrow, inner deep sorrow in someone who has undergone misfortune, who has experienced suffering. This is called sorrow.',
          ],
          spokenBy: 'Ven. Sariputta',
        },
      ],
    },
    {
      id: 'S-5',
      text: 'Lamentation',
      elaborations: [
        {
          reference: 'MN 141',
          text: [
            'What is lamentation? The wail, lament, wailing, lamenting, state of wailing and lamentation in someone who has undergone misfortune, who has experienced suffering. This is called lamentation.',
          ],
          spokenBy: 'Ven. Sariputta',
        },
      ],
    },
    {
      id: 'S-6',
      text: 'Pain',
      elaborations: [
        {
          reference: 'MN 141',
          text: [
            'What is pain? Physical pain, physical displeasure, the painful, unpleasant feeling that’s born from physical contact. This is called pain.',
          ],
          spokenBy: 'Ven. Sariputta',
        },
      ],
    },
    {
      id: 'S-7',
      text: 'Sadness',
      elaborations: [
        {
          reference: 'MN 141',
          text: [
            'What is sadness? Mental pain, mental displeasure, the painful, unpleasant feeling that’s born from mind contact. This is called sadness.',
          ],
          spokenBy: 'Ven. Sariputta',
        },
      ],
    },
    {
      id: 'S-8',
      text: 'Distress',
      elaborations: [
        {
          reference: 'MN 141',
          text: [
            'What is distress? The stress, distress, state of stress and distress in someone who has undergone misfortune, who has experienced suffering. This is called distress.',
          ],
          spokenBy: 'Ven. Sariputta',
        },
      ],
    },
    {
      id: 'S-9',
      text: 'Not getting what you wished for',
      elaborations: [
        {
          reference: 'MN 141',
          text: [
            'What is ‘not getting what you wish for is suffering’? In sentient beings who are liable to be reborn, such a wish arises: ‘Oh, if only we were not liable to be reborn! If only rebirth would not come to us!’ But you can’t get that by wishing. This is: ‘not getting what you wish for is suffering.’ In sentient beings who are liable to grow old … fall ill … die … experience sorrow, lamentation, pain, sadness, and distress, such a wish arises: ‘Oh, if only we were not liable to experience sorrow, lamentation, pain, sadness, and distress! If only sorrow, lamentation, pain, sadness, and distress would not come to us!’ But you can’t get that by wishing. This is: ‘not getting what you wish for is suffering.’',
          ],
          spokenBy: 'Ven. Sariputta',
        },
      ],
    },
    {
      id: 'S-10',
      text: 'The five grasping aggregates',
      seeMore: ['SN 22.104', 'SN 22'],
      children: [
        {
          id: 'S-10.1',
          text: 'Form',
          seeMore: ['SN 22.57', 'MN 28'],
          children: [
            {
              id: 'S-10.1.1',
              text: 'Earth element',
              seeMore: ['MN 62'],
              elaborations: [
                {
                  reference: 'MN 28',
                  text: [
                    'What is the earth element? The earth element may be interior or exterior. And what is the interior earth element? Anything hard, solid, and appropriated that’s internal, pertaining to an individual. This includes: head hair, body hair, nails, teeth, skin, flesh, sinews, bones, bone marrow, kidneys, heart, liver, diaphragm, spleen, lungs, intestines, mesentery, undigested food, feces, or anything else hard, solid, and appropriated that’s internal, pertaining to an individual. This is called the interior earth element. The interior earth element and the exterior earth element are just the earth element. This should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ When you truly see with right understanding, you grow disillusioned with the earth element, detaching the mind from the earth element.',
                  ],
                },
              ],
            },
            {
              id: 'S-10.1.2',
              text: 'Water element',
              seeMore: ['MN 62'],
              elaborations: [
                {
                  reference: 'MN 28',
                  text: [
                    'What is the water element? The water element may be interior or exterior. And what is the interior water element? Anything that’s water, watery, and appropriated that’s internal, pertaining to an individual. This includes: bile, phlegm, pus, blood, sweat, fat, tears, grease, saliva, snot, synovial fluid, urine, or anything else that’s water, watery, and appropriated that’s internal, pertaining to an individual. This is called the interior water element. The interior water element and the exterior water element are just the water element. This should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ When you truly see with right understanding, you grow disillusioned with the water element, detaching the mind from the water element.',
                  ],
                },
              ],
            },
            {
              id: 'S-10.1.3',
              text: 'Fire element',
              seeMore: ['MN 62'],
              elaborations: [
                {
                  reference: 'MN 28',
                  text: [
                    'What is the fire element? The fire element may be interior or exterior. And what is the interior fire element? Anything that’s fire, fiery, and appropriated that’s internal, pertaining to an individual. This includes: that which warms, that which ages, that which heats you up when feverish, that which properly digests food and drink, or anything else that’s fire, fiery, and appropriated that’s internal, pertaining to an individual. This is called the interior fire element. The interior fire element and the exterior fire element are just the fire element. This should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ When you truly see with right understanding, you grow disillusioned with the fire element, detaching the mind from the fire element.',
                  ],
                },
              ],
            },
            {
              id: 'S-10.1.4',
              text: 'Air element',
              seeMore: ['MN 62'],
              elaborations: [
                {
                  reference: 'MN 28',
                  text: [
                    'What is the air element? The air element may be interior or exterior. And what is the interior air element? Anything that’s wind, windy, and appropriated that’s internal, pertaining to an individual. This includes: winds that go up or down, winds in the belly or the bowels, winds that flow through the limbs, in-breaths and out-breaths, or anything else that’s wind, windy, and appropriated that’s internal, pertaining to an individual. This is called the interior air element. The interior air element and the exterior air element are just the air element. This should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ When you truly see with right understanding, you reject the air element, detaching the mind from the air element.',
                  ],
                },
              ],
            },
          ],
          elaborations: [
            {
              text: [
                'What is form? The four primary elements, and form derived from the four primary elements. This is called form. Form originates from food. When food ceases, form ceases. The practice that leads to the cessation of form is simply this noble eightfold path.',
              ],
              reference: 'SN 22.56',
            },
          ],
        },
        {
          id: 'S-10.2',
          text: 'Feeling',
          seeMore: ['SN 22.57'],
          elaborations: [
            {
              text: [
                'What is feeling? There are these six classes of feeling: feeling born of contact through the eye, ear, nose, tongue, body, and mind. This is called feeling. Feeling originates from contact. When contact ceases, feeling ceases. The practice that leads to the cessation of feelings is simply this noble eightfold path.',
              ],
              reference: 'SN 22.56',
            },
          ],
        },
        {
          id: 'S-10.3',
          text: 'Perception',
          seeMore: ['SN 22.57'],
          elaborations: [
            {
              text: [
                'What is perception? There are these six classes of perception: perceptions of sights, sounds, smells, tastes, touches, and thoughts. This is called perception. Perception originates from contact. When contact ceases, perception ceases. The practice that leads to the cessation of perceptions is simply this noble eightfold path.',
              ],
              reference: 'SN 22.56',
            },
          ],
        },
        {
          id: 'S-10.4',
          text: 'Choices',
          seeMore: ['SN 22.57'],
          elaborations: [
            {
              text: [
                'What are choices? There are these six classes of choices: choices regarding sights, sounds, smells, tastes, touches, and thoughts. These are called choices. Choices originate from contact. When contact ceases, choices cease. The practice that leads to the cessation of choices is simply this noble eightfold path.',
              ],
              reference: 'SN 22.56',
            },
          ],
        },
        {
          id: 'S-10.5',
          text: 'Consciousness',
          seeMore: ['SN 22.57'],
          elaborations: [
            {
              text: [
                'What is consciousness? There are these six classes of consciousness: eye, ear, nose, tongue, body, and mind consciousness. This is called consciousness. Consciousness originates from name and form. When name and form cease, consciousness ceases. The practice that leads to the cessation of consciousness is simply this noble eightfold path.',
              ],
              reference: 'SN 22.56',
            },
          ],
        },
      ],
      elaborations: [
        {
          reference: 'SN 56.13',
          text: [
            'What is the noble truth of suffering? You should say: ‘The five grasping aggregates’. That is: form, feeling, perception, choices, and consciousness. This is called the noble truth of suffering.',
          ],
        },
      ],
    },
    {
      id: 'S-11',
      text: 'The six interior sense fields',
      children: [
        {
          id: 'S-11.1',
          text: 'Eyes',
          elaborations: [
            {
              text: [
                'The eye is suffering. What’s suffering is not-self. And what’s not-self should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ Seeing this, a learned noble disciple grows disillusioned with the eye. Being disillusioned, desire fades away. When desire fades away they’re freed. When they’re freed, they know they’re freed.',
              ],
              reference: 'SN 35.1',
            },
          ],
        },
        {
          id: 'S-11.2',
          text: 'Ears',
          elaborations: [
            {
              text: [
                'The ear is suffering. What’s suffering is not-self. And what’s not-self should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ Seeing this, a learned noble disciple grows disillusioned with the ear. Being disillusioned, desire fades away. When desire fades away they’re freed. When they’re freed, they know they’re freed.',
              ],
              reference: 'SN 35.1',
            },
          ],
        },
        {
          id: 'S-11.3',
          text: 'Nose',
          elaborations: [
            {
              text: [
                'The nose is suffering. What’s suffering is not-self. And what’s not-self should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ Seeing this, a learned noble disciple grows disillusioned with nose. Being disillusioned, desire fades away. When desire fades away they’re freed. When they’re freed, they know they’re freed.',
              ],
              reference: 'SN 35.1',
            },
          ],
        },
        {
          id: 'S-11.4',
          text: 'Tongue',
          elaborations: [
            {
              text: [
                'The tongue is suffering. What’s suffering is not-self. And what’s not-self should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ Seeing this, a learned noble disciple grows disillusioned with the tongue. Being disillusioned, desire fades away. When desire fades away they’re freed. When they’re freed, they know they’re freed.',
              ],
              reference: 'SN 35.1',
            },
          ],
        },
        {
          id: 'S-11.5',
          text: 'Body',
          elaborations: [
            {
              text: [
                'The body is suffering. What’s suffering is not-self. And what’s not-self should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ Seeing this, a learned noble disciple grows disillusioned with the body. Being disillusioned, desire fades away. When desire fades away they’re freed. When they’re freed, they know they’re freed.',
              ],
              reference: 'SN 35.1',
            },
          ],
        },
        {
          id: 'S-11.6',
          text: 'Mind',
          elaborations: [
            {
              text: [
                'The mind is suffering. What’s suffering is not-self. And what’s not-self should be truly seen with right understanding like this: ‘This is not mine, I am not this, this is not my self.’ Seeing this, a learned noble disciple grows disillusioned with the mind. Being disillusioned, desire fades away. When desire fades away they’re freed. When they’re freed, they know they’re freed.',
              ],
              reference: 'SN 35.1',
            },
          ],
        },
      ],
      elaborations: [
        {
          reference: 'SN 56.14',
          text: [
            'What is the noble truth of suffering? You should say: ‘The six interior sense fields’. What six? The sense fields of the eye, ear, nose, tongue, body, and mind. This is called the noble truth of suffering.',
          ],
        },
      ],
    },
  ],
};
